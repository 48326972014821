// Variables
//
// Copy settings from this file into the provided `_custom.scss` to override
// the Bootstrap defaults without modifying key, versioned files.
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Table of Contents
//
// Color system
// Options
// Spacing
// Body
// Links
// Grid breakpoints
// Grid containers
// Grid columns
// Fonts
// Components
// Tables
// Buttons
// Forms
// Dropdowns
// Z-index master list
// Navs
// Navbar
// Pagination
// Jumbotron
// Form states and alerts
// Cards
// Tooltips
// Popovers
// Badges
// Modals
// Alerts
// Progress bars
// List group
// Image thumbnails
// Figures
// Breadcrumbs
// Carousel
// Close
// Code


//
// Color system
//

$white:  #fff;
// $gray-100: #f8f9fa;
$gray-200: #e9ecef;
// $gray-300: #dee2e6;
// $gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #526173;
// $gray-700: #495057;
// $gray-800: #343a40;
// $gray-900: #212529;
$black:  #000;

// $grays: (
//   100: $gray-100,
//   200: $gray-200,
//   300: $gray-300,
//   400: $gray-400,
//   500: $gray-500,
//   600: $gray-600,
//   700: $gray-700,
//   800: $gray-800,
//   900: $gray-900
// );

$blue:    #1e71ab;
// $indigo:  #6610f2;
$purple:  #3f337c;
// $pink:    #e83e8c;
$red:     #db3046;
$orange:  #ed963b;
// $yellow:  #ffc107;
// $green:   #28a745;
$teal:    #22ac7c;
// $cyan:    #007988;

// $colors: (
//   blue: $blue,
//   indigo: $indigo,
//   purple: $purple,
//   pink: $pink,
//   red: $red,
//   orange: $orange,
//   yellow: $yellow,
//   green: $green,
//   teal: $teal,
//   cyan: $cyan,
//   white: $white,
//   gray: $gray-600,
//   gray-dark: $gray-800
// );

$theme-colors: (
  primary: #007988,
  secondary: $orange,
  // success: $green,
  // info: $cyan,
  // warning: $yellow,
  // danger: $red,
  // light: $gray-100,
  // dark: $gray-800
);

// Set a specific jump point for requesting color jumps
// $theme-color-interval: 8%;


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

// $enable-rounded:            true;
// $enable-shadows:            false;
// $enable-gradients:          false;
// $enable-transitions:        true;
// $enable-hover-media-query:  false;
// $enable-grid-classes:       true;
// $enable-print-styles:       true;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// $spacer: 1rem;
// $spacers: (
//   0: 0,
//   1: ($spacer * .25),
//   2: ($spacer * .5),
//   3: $spacer,
//   4: ($spacer * 1.5),
//   5: ($spacer * 3)
// );

// This variable affects the `.h-*` and `.w-*` classes.
// $sizes: (
//   25: 25%,
//   50: 50%,
//   75: 75%,
//   100: 100%
// );

// Body
//
// Settings for the `<body>` element.

$body-bg:       #e5f1f3;
$body-color:    $gray-600;

// Links
//
// Style anchor elements.

// $link-color:            theme-color("primary");
$link-decoration:       underline;
$link-hover-color: lighten( #007988, 10% );
$link-hover-decoration: underline;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  ss: 460px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);
// @include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
// @include _assert-starts-at-zero($grid-breakpoints);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 960px,
//   xl: 1140px
// );
// @include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

// $grid-columns: 12;
// $grid-gutter-width: 30px;

// Components
//
// Define common padding and border radius sizes and more.

// $line-height-lg:         1.5;
// $line-height-sm:         1.5;

// $border-width: 1px;

// $border-radius:          .25rem;
// $border-radius-lg:       .3rem;
// $border-radius-sm:       .2rem;

// $component-active-color: $white;
// $component-active-bg:    theme-color("primary");

// $caret-width:            .3em;

// $transition-base:        all .2s ease-in-out;
// $transition-fade:        opacity .15s linear;
// $transition-collapse:    height .35s ease;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
// $font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
// $font-family-base:       $font-family-sans-serif;

 // $font-size-base: 1rem; Assumes the browser default, typically `16px`
// $font-size-lg:   1.25rem;
// $font-size-sm:   .875rem;

// $font-weight-normal: normal;
// $font-weight-bold: bold;

// $font-weight-base: $font-weight-normal;
// $line-height-base: 1.5;

$h1-font-size: remcalc(36px);
$h2-font-size: remcalc(28px);
$h3-font-size: remcalc(24px);
$h4-font-size: remcalc(20px);
$h5-font-size: remcalc(18px);
// $h6-font-size: 1rem;

$headings-margin-bottom: remcalc(16px);
// $headings-font-family:   inherit;
// $headings-font-weight:   500;
// $headings-line-height:   1.1;
$headings-color:         #007988;

// $display1-size: 6rem;
// $display2-size: 5.5rem;
// $display3-size: 4.5rem;
// $display4-size: 3.5rem;

// $display1-weight:     300;
// $display2-weight:     300;
// $display3-weight:     300;
// $display4-weight:     300;
// $display-line-height: $headings-line-height;

// $lead-font-size:   1.25rem;
// $lead-font-weight: 300;

// $small-font-size: 80%;

// $text-muted: $gray-600;

// $blockquote-small-color:  $gray-600;
// $blockquote-font-size:    ($font-size-base * 1.25);

// $hr-border-color: rgba($black,.1);
// $hr-border-width: $border-width;

// $mark-padding: .2em;

// $dt-font-weight: $font-weight-bold;

// $kbd-box-shadow:         inset 0 -.1rem 0 rgba($black,.25);
// $nested-kbd-font-weight: $font-weight-bold;

// $list-inline-padding: 5px;

// $mark-bg: #fcf8e3;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// $table-cell-padding:            .75rem;
// $table-cell-padding-sm:         .3rem;

// $table-bg:                      transparent;
// $table-accent-bg:               rgba($black,.05);
// $table-hover-bg:                rgba($black,.075);
// $table-active-bg:               $table-hover-bg;

// $table-border-width:            $border-width;
// $table-border-color:            $gray-200;

// $table-head-bg:                 $gray-200;
// $table-head-color:              $gray-700;

// $table-inverse-bg:              $gray-900;
// $table-inverse-accent-bg:       rgba($white, .05);
// $table-inverse-hover-bg:        rgba($white, .075);
// $table-inverse-border-color:    lighten($gray-900, 7.5%);
// $table-inverse-color:           $body-bg;


// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

// $input-btn-padding-y:       .5rem;
// $input-btn-padding-x:       .75rem;
// $input-btn-line-height:     1.25;

// $input-btn-padding-y-sm:    .25rem;
// $input-btn-padding-x-sm:    .5rem;
// $input-btn-line-height-sm:  1.5;

// $input-btn-padding-y-lg:    .5rem;
// $input-btn-padding-x-lg:    1rem;
// $input-btn-line-height-lg:  1.5;

// $btn-font-weight:                $font-weight-normal;
// $btn-box-shadow:                 inset 0 1px 0 rgba($white,.15), 0 1px 1px rgba($black,.075);
// $btn-focus-box-shadow:           0 0 0 3px rgba(theme-color("primary"), .25);
// $btn-active-box-shadow:          inset 0 3px 5px rgba($black,.125);

// $btn-link-disabled-color:        $gray-600;

// $btn-block-spacing-y:            .5rem;

// Allows for customizing button radius independently from global border radius
// $btn-border-radius:              $border-radius;
// $btn-border-radius-lg:           $border-radius-lg;
// $btn-border-radius-sm:           $border-radius-sm;

// $btn-transition:                 all .15s ease-in-out;


// Forms

// $input-bg:                       $white;
$input-disabled-bg:              #e5e5e5;

// $input-color:                    $gray-700;
// $input-border-color:             rgba($black,.15);
 // $input-btn-border-width:        $border-width; For form controls and buttons
// $input-box-shadow:               inset 0 1px 1px rgba($black,.075);

$input-border-radius:            0;
// $input-border-radius-lg:         $border-radius-lg;
// $input-border-radius-sm:         $border-radius-sm;

// $input-focus-bg:                 $input-bg;
$input-focus-border-color:       #2ea2f8;
$input-focus-box-shadow:         transparent;
// $input-focus-color:              $input-color;

$input-placeholder-color:        $gray-500;

// $input-height-border:           $input-btn-border-width * 2;

// $input-height-inner:            ($font-size-base * $input-btn-line-height) + ($input-btn-padding-y * 2);
// $input-height:                  calc(#{$input-height-inner} + #{$input-height-border});

// $input-height-inner-sm:         ($font-size-sm * $input-btn-line-height-sm) + ($input-btn-padding-y-sm * 2);
// $input-height-sm:               calc(#{$input-height-inner-sm} + #{$input-height-border});

// $input-height-inner-lg:         ($font-size-sm * $input-btn-line-height-lg) + ($input-btn-padding-y-lg * 2);
// $input-height-lg:               calc(#{$input-height-inner-lg} + #{$input-height-border});

// $input-transition:               border-color ease-in-out .15s, box-shadow ease-in-out .15s;

// $form-text-margin-top:     .25rem;

// $form-check-margin-bottom:  .5rem;
// $form-check-input-gutter:   1.25rem;
// $form-check-input-margin-y: .25rem;
// $form-check-input-margin-x: .25rem;

// $form-check-inline-margin-x: .75rem;

// $form-group-margin-bottom:       1rem;

// $input-group-addon-bg:           $gray-200;
// $input-group-addon-border-color: $input-border-color;

// $custom-control-gutter:   1.5rem;
// $custom-control-spacer-y: .25rem;
// $custom-control-spacer-x: 1rem;

// $custom-control-indicator-size:       1rem;
// $custom-control-indicator-bg:         #ddd;
// $custom-control-indicator-bg-size:    50% 50%;
// $custom-control-indicator-box-shadow: inset 0 .25rem .25rem rgba($black,.1);

// $custom-control-indicator-disabled-bg:       $gray-200;
// $custom-control-description-disabled-color:  $gray-600;

// $custom-control-indicator-checked-color:      $white;
// $custom-control-indicator-checked-bg:         theme-color("primary");
// $custom-control-indicator-checked-box-shadow: none;

// $custom-control-indicator-focus-box-shadow: 0 0 0 1px $body-bg, 0 0 0 3px theme-color("primary");

// $custom-control-indicator-active-color:      $white;
// $custom-control-indicator-active-bg:         lighten(theme-color("primary"), 35%);
// $custom-control-indicator-active-box-shadow: none;

// $custom-checkbox-indicator-border-radius: $border-radius;
// $custom-checkbox-indicator-icon-checked: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23");

// $custom-checkbox-indicator-indeterminate-bg: theme-color("primary");
// $custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color;
// $custom-checkbox-indicator-icon-indeterminate: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3E%3C/svg%3E"), "#", "%23");
// $custom-checkbox-indicator-indeterminate-box-shadow: none;

// $custom-radio-indicator-border-radius: 50%;
// $custom-radio-indicator-icon-checked: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$custom-control-indicator-checked-color}'/%3E%3C/svg%3E"), "#", "%23");

// $custom-select-padding-y:          .375rem;
// $custom-select-padding-x:          .75rem ;
// $custom-select-height:              $input-height ;
// $custom-select-indicator-padding:   1rem; Extra padding to account for the presence of the background-image based indicator
// $custom-select-line-height:         $input-btn-line-height;
// $custom-select-color:               $input-color;
// $custom-select-disabled-color:      $gray-600;
// $custom-select-bg:            $white;
// $custom-select-disabled-bg:   $gray-200;
// $custom-select-bg-size:       8px 10px; In pixels because image dimensions
// $custom-select-indicator-color: #333;
// $custom-select-indicator:     str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"), "#", "%23");
// $custom-select-border-width:  $input-btn-border-width;
// $custom-select-border-color:  $input-border-color;
// $custom-select-border-radius: $border-radius;

// $custom-select-focus-border-color: lighten(theme-color("primary"), 25%);
// $custom-select-focus-box-shadow:   inset 0 1px 2px rgba($black, .075), 0 0 5px rgba($custom-select-focus-border-color, .5);

// $custom-select-font-size-sm:  75%;
// $custom-select-height-sm: $input-height-sm;

// $custom-file-height:           2.5rem;
// $custom-file-width:            14rem;
// $custom-file-focus-box-shadow: 0 0 0 .075rem $white, 0 0 0 .2rem theme-color("primary");

// $custom-file-padding-y:     1rem;
// $custom-file-padding-x:     .5rem;
// $custom-file-line-height:   1.5;
// $custom-file-color:         $gray-700;
// $custom-file-bg:            $white;
// $custom-file-border-width:  $border-width;
// $custom-file-border-color:  $input-border-color;
// $custom-file-border-radius: $border-radius;
// $custom-file-box-shadow:    inset 0 .2rem .4rem rgba($black,.05);
// $custom-file-button-color:  $custom-file-color;
// $custom-file-button-bg:     $gray-200;
// $custom-file-text: (
//   placeholder: (
//     en: "Choose file..."
//   ),
//   button-label: (
//     en: "Browse"
//   )
// );


// Form validation
// $form-feedback-valid-color:   theme-color("success");
// $form-feedback-invalid-color: theme-color("danger");


// Dropdowns
//
// Dropdown menu container and contents.

// $dropdown-min-width:             10rem;
// $dropdown-padding-y:             .5rem;
// $dropdown-spacer:                .125rem;
// $dropdown-bg:                    $white;
// $dropdown-border-color:          rgba($black,.15);
// $dropdown-border-width:          $border-width;
// $dropdown-divider-bg:            $gray-200;
// $dropdown-box-shadow:            0 .5rem 1rem rgba($black,.175);

// $dropdown-link-color:            $gray-900;
// $dropdown-link-hover-color:      darken($gray-900, 5%);
// $dropdown-link-hover-bg:         $gray-100;

// $dropdown-link-active-color:     $component-active-color;
// $dropdown-link-active-bg:        $component-active-bg;

// $dropdown-link-disabled-color:   $gray-600;

// $dropdown-item-padding-y:        .25rem;
// $dropdown-item-padding-x:        1.5rem;

// $dropdown-header-color:          $gray-600;


// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// $zindex-dropdown:           1000;
// $zindex-sticky:             1020;
// $zindex-fixed:              1030;
// $zindex-modal-backdrop:     1040;
// $zindex-modal:              1050;
// $zindex-popover:            1060;
// $zindex-tooltip:            1070;

// Navs

// $nav-link-padding-y:            .5rem;
$nav-link-padding-x:            2rem;
// $nav-link-disabled-color:       $gray-600;

// $nav-tabs-border-color:                       map-get($theme-colors, primary);
// $nav-tabs-border-width:                       $border-width;
// $nav-tabs-border-radius:                      $border-radius;
// $nav-tabs-link-hover-border-color:            $gray-200;
$nav-tabs-link-active-color:                  $white;
$nav-tabs-link-active-bg:                     map-get($theme-colors, primary);
// $nav-tabs-link-active-border-color:           #ddd;

// $nav-pills-border-radius:     $border-radius;
// $nav-pills-link-active-color: $component-active-color;
// $nav-pills-link-active-bg:    $component-active-bg;

// Navbar

// $navbar-padding-y:                  ($spacer / 2);
// $navbar-padding-x:                  $spacer;

// $navbar-brand-font-size:            $font-size-lg;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
// $nav-link-height:                   $navbar-brand-font-size * $line-height-base;
// $navbar-brand-height:               ($font-size-base * $line-height-base + $nav-link-padding-y * 2);
// $navbar-brand-padding-y:            ($navbar-brand-height - $nav-link-height) / 2;

// $navbar-toggler-padding-y:           .25rem;
// $navbar-toggler-padding-x:           .75rem;
// $navbar-toggler-font-size:           $font-size-lg;
// $navbar-toggler-border-radius:       $btn-border-radius;

// $navbar-dark-color:                 rgba($white,.5);
// $navbar-dark-hover-color:           rgba($white,.75);
// $navbar-dark-active-color:          rgba($white,1);
// $navbar-dark-disabled-color:        rgba($white,.25);
// $navbar-dark-toggler-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");
// $navbar-dark-toggler-border-color:  rgba($white,.1);

// $navbar-light-color:                rgba($black,.5);
// $navbar-light-hover-color:          rgba($black,.7);
// $navbar-light-active-color:         rgba($black,.9);
// $navbar-light-disabled-color:       rgba($black,.3);
// $navbar-light-toggler-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");
// $navbar-light-toggler-border-color: rgba($black,.1);

// Pagination

// $pagination-padding-y:                .5rem;
// $pagination-padding-x:                .75rem;
// $pagination-padding-y-sm:             .25rem;
// $pagination-padding-x-sm:             .5rem;
// $pagination-padding-y-lg:             .75rem;
// $pagination-padding-x-lg:             1.5rem;
// $pagination-line-height:              1.25;

// $pagination-color:                     $link-color;
// $pagination-bg:                        $white;
// $pagination-border-width:              $border-width;
// $pagination-border-color:              #ddd;

// $pagination-hover-color:               $link-hover-color;
// $pagination-hover-bg:                  $gray-200;
// $pagination-hover-border-color:        #ddd;

// $pagination-active-color:              $white;
// $pagination-active-bg:                 theme-color("primary");
// $pagination-active-border-color:       theme-color("primary");

// $pagination-disabled-color:            $gray-600;
// $pagination-disabled-bg:               $white;
// $pagination-disabled-border-color:     #ddd;


// Jumbotron

// $jumbotron-padding:              2rem;
// $jumbotron-bg:                   $gray-200;


// Cards

// $card-spacer-y:            .75rem;
// $card-spacer-x:            1.25rem;
// $card-border-width:        1px;
// $card-border-radius:       $border-radius;
// $card-border-color:        rgba($black,.125);
// $card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width});
// $card-cap-bg:              rgba($black, .03);
// $card-bg:                  $white;

// $card-img-overlay-padding: 1.25rem;

// $card-deck-margin:          ($grid-gutter-width / 2);

// $card-columns-count:        3;
// $card-columns-gap:          1.25rem;
// $card-columns-margin:       $card-spacer-y;


// Tooltips

// $tooltip-max-width:           200px;
// $tooltip-color:               $white;
// $tooltip-bg:                  $black;
// $tooltip-opacity:             .9;
// $tooltip-padding-y:           3px;
// $tooltip-padding-x:           8px;
// $tooltip-margin:              0;


// $tooltip-arrow-width:         5px;
// $tooltip-arrow-height:        5px;
// $tooltip-arrow-color:         $tooltip-bg;


// Popovers

// $popover-inner-padding:               1px;
// $popover-bg:                          $white;
// $popover-max-width:                   276px;
// $popover-border-width:                $border-width;
// $popover-border-color:                rgba($black,.2);
// $popover-box-shadow:                  0 5px 10px rgba($black,.2);

// $popover-header-bg:                    darken($popover-bg, 3%);
// $popover-header-color:                 $headings-color;
// $popover-header-padding-y:             8px;
// $popover-header-padding-x:             14px;

// $popover-body-color:               $body-color;
// $popover-body-padding-y:           9px;
// $popover-body-padding-x:           14px;

// $popover-arrow-width:                 10px;
// $popover-arrow-height:                5px;
// $popover-arrow-color:                 $popover-bg;

// $popover-arrow-outer-width:           ($popover-arrow-width + 1px);
// $popover-arrow-outer-color:           fade-in($popover-border-color, .05);


// Badges

// $badge-color:                 $white;
// $badge-font-size:             75%;
// $badge-font-weight:           $font-weight-bold;
// $badge-padding-y:             .25em;
// $badge-padding-x:             .4em;

// $badge-pill-padding-x:        .6em;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
// $badge-pill-border-radius:    10rem;


// Modals

// Padding applied to the modal body
// $modal-inner-padding:         15px;

$modal-dialog-margin:         60px 10px;
$modal-dialog-margin-y-sm-up: 80px;

// $modal-title-line-height:     $line-height-base;

// $modal-content-bg:               $white;
// $modal-content-border-color:     rgba($black,.2);
// $modal-content-border-width:     $border-width;
// $modal-content-box-shadow-xs:    0 3px 9px rgba($black,.5);
// $modal-content-box-shadow-sm-up: 0 5px 15px rgba($black,.5);

// $modal-backdrop-bg:           $black;
// $modal-backdrop-opacity:      .5;
// $modal-header-border-color:   $gray-200;
// $modal-footer-border-color:   $modal-header-border-color;
// $modal-header-border-width:   $modal-content-border-width;
// $modal-footer-border-width:   $modal-header-border-width;
// $modal-header-padding:        15px;

// $modal-lg:                    800px;
// $modal-md:                    500px;
// $modal-sm:                    300px;

// $modal-transition:            transform .3s ease-out;


// Alerts
//
// Define alert colors, border radius, and padding.

// $alert-padding-y:             .75rem;
// $alert-padding-x:             1.25rem;
// $alert-margin-bottom:         1rem;
// $alert-border-radius:         $border-radius;
// $alert-link-font-weight:      $font-weight-bold;
// $alert-border-width:          $border-width;


// Progress bars

// $progress-height:               1rem;
// $progress-font-size:            .75rem;
// $progress-bg:                   $gray-200;
// $progress-border-radius:        $border-radius;
// $progress-box-shadow:           inset 0 .1rem .1rem rgba($black,.1);
// $progress-bar-color:            $white;
// $progress-bar-bg:               theme-color("primary");
// $progress-bar-animation-timing: 1s linear infinite;
// $progress-bar-transition:       width .6s ease;

// List group

// $list-group-bg:                  $white;
// $list-group-border-color:        rgba($black,.125);
// $list-group-border-width:        $border-width;
// $list-group-border-radius:       $border-radius;

// $list-group-item-padding-y:      .75rem;
// $list-group-item-padding-x:      1.25rem;

// $list-group-hover-bg:                 $gray-100;
// $list-group-active-color:             $component-active-color;
// $list-group-active-bg:                $component-active-bg;
// $list-group-active-border-color:      $list-group-active-bg;

// $list-group-disabled-color:      $gray-600;
// $list-group-disabled-bg:         $list-group-bg;

// $list-group-action-color:             $gray-700;
// $list-group-action-hover-color:       $list-group-action-color;

// $list-group-action-active-color:      $body-color;
// $list-group-action-active-bg:         $gray-200;


// Image thumbnails

// $thumbnail-padding:           .25rem;
// $thumbnail-bg:                $body-bg;
// $thumbnail-border-width:      $border-width;
// $thumbnail-border-color:      #ddd;
// $thumbnail-border-radius:     $border-radius;
// $thumbnail-box-shadow:        0 1px 2px rgba($black,.075);
// $thumbnail-transition:        all .2s ease-in-out;


// Figures

// $figure-caption-font-size: 90%;
// $figure-caption-color:     $gray-600;


// Breadcrumbs

// $breadcrumb-padding-y:          .75rem;
$breadcrumb-padding-x:          0;
// $breadcrumb-item-padding:       .5rem;

$breadcrumb-bg:                 transparent;
$breadcrumb-divider-color:      #99cad0;
$breadcrumb-active-color:       #99cad0;
$breadcrumb-divider:            "|";


// Carousel

// $carousel-control-color:                      $white;
// $carousel-control-width:                      15%;
// $carousel-control-opacity:                    .5;

// $carousel-indicator-width:                    30px;
// $carousel-indicator-height:                   3px;
// $carousel-indicator-spacer:                   3px;
// $carousel-indicator-active-bg:                $white;

// $carousel-caption-width:                      70%;
// $carousel-caption-color:                      $white;

// $carousel-control-icon-width:                 20px;

// $carousel-control-prev-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"), "#", "%23");
// $carousel-control-next-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"), "#", "%23");

// $carousel-transition:           transform .6s ease;


// Close

// $close-font-size:             $font-size-base * 1.5;
// $close-font-weight:           $font-weight-bold;
// $close-color:                 $black;
// $close-text-shadow:           0 1px 0 $white;

// Code

// $code-font-size:              90%;
// $code-padding-y:              .2rem;
// $code-padding-x:              .4rem;
// $code-color:                  #bd4147;
// $code-bg:                     $gray-100;

// $kbd-color:                   $white;
// $kbd-bg:                      $gray-900;

// $pre-color:                   $gray-900;
// $pre-scrollable-max-height:   340px;
