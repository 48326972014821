%slick-dots {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		display: inline-block;

		button {
			cursor: pointer;
			padding: remcalc(10px);
			display: block;
			font-size: 0;
			position: relative;
			appearance: none;
			background: transparent;
			border: none;
			height: remcalc(15px);
			width: remcalc(15px);

			&::before {
				transition: height 200ms ease, width 200ms ease, opacity 200ms ease;
				position: absolute;
				left: 50%;
				right: 50%;
				transform: translate(-50%, -50%);
				display: block;
				content: '';
				height: remcalc(6px);
				width: remcalc(6px);
				opacity: 0.6;
				border-radius: 100%;
			}

			&:hover {

				&::before {
					height: remcalc(10px);
					width: remcalc(10px);
				}
			}

		}

		&.slick-active {

			button {
				outline: none;

				&::before {
					opacity: 1;
					height: remcalc(10px);
					width: remcalc(10px);
				}
			}
		}
	}
}

.preloader {
	position: absolute;
	z-index: 10;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: $white;

	.spinner {
        position: absolute;
    	left: 50%;
    	top: 50%;
    	transform: translate(-50%, -50%);
	    width: 70px;
	    text-align: center;

		> div {
		    width: 18px;
		    height: 18px;
		    background-color: map-get($theme-colors, primary);

		    border-radius: 100%;
		    display: inline-block;
		    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
		}

		.bounce1 {
		    animation-delay: -0.32s;
		}

		.bounce2 {
		    animation-delay: -0.16s;
		}
	}

	@keyframes sk-bouncedelay {

	    0%, 80%, 100% {
	        transform: scale(0);
	    }

	    40% {
	        transform: scale(1.0);
	    }
	}
}



.welcome-slider {

	.welcome-slider-desc {
		background-color: #fefef4;
		background-image: url("../Images/slider-buch-bg.svg");
		background-position: bottom remcalc(-70px) right;
		background-repeat: no-repeat;
		background-size: remcalc(245px) remcalc(236px);
		padding: {
			top: remcalc(20px);
			right: remcalc(20px);
			bottom: remcalc(60px);
			left: remcalc(20px);
		}

		.welcome-slider-hl {
			font-size: remcalc(28px);
			margin-bottom: $headings-margin-bottom;
  			font-family: $headings-font-family;
  			font-weight: $headings-font-weight;
  			line-height: $headings-line-height;
  			color: $headings-color;
		}
	}

	.slick-next,
	.slick-prev {
		position: absolute;
		bottom: remcalc(5px);
		right: 0;
		appearance: none;
		border: none;
		background: transparent;
		color: map-get($theme-colors, primary);
		padding: remcalc(15px);
		font-size: remcalc(20px);
		cursor: pointer;
		opacity: 1;

		@include hover-state {
			opacity: 0.7;
			outline: none;
		}

	}

	.slick-prev {
		right: remcalc(41px);
		z-index: 1;
	}

	.slick-dots {
		@extend %slick-dots;
		position: absolute;
		bottom: remcalc(20px);
		left: remcalc(20px);

		li {

			button {

				&::before {
					background-color: map-get($theme-colors, primary);
				}
			}
		}
	}

	@include media-breakpoint-up(sm) {

		.welcome-slider-img {
		    position: relative;
			display: block;
			height: remcalc(287px);
			overflow: hidden;

			img {
				position: absolute;
			    top: 50%;
			    left: 50%;
			    transform: translate(-50%, -50%);
			    display: block;
			    min-height: 100%;
			    min-width: 100%;
			    max-width: none;
			    width: auto;
			}
		}
	}


	@include media-breakpoint-up(lg) {

		.welcome-slider-item {
			display: flex !important;
		}

		.welcome-slider-img {
		    position: relative;
			display: block;
			height: auto;
			overflow: visible;
			margin: {
				left: 0;
				right: 0;
			}

			img {
				position: relative;
			    top: auto;
			    left: auto;
			    transform: none;
			    display: block;
			    min-height: auto;
			    min-width: auto;
			    max-width: 100%;
			    width: auto;
			}
		}

		.welcome-slider-desc {
			width: remcalc(546px);
			margin: 0;
			padding: {
				top: remcalc(45px);
				right: remcalc(45px);
				bottom: remcalc(60px);
				left: remcalc(45px);
			}
		}

		.animation-wrapper {
			max-height: 170px;
		}

		.welcome-slider-teaser {
			display: none;
		}

		.slick-dots {
			left: 63.5%;
			bottom: remcalc(25px);
		}
	}

	@include media-breakpoint-up(xl) {

		.animation-wrapper {
			max-height: 240px;
		}
		.welcome-slider-teaser {
			display: block;
		}
	}

	.welcome-slider-item {
		display: none !important;

		&:first-child {
			display: block !important;

			@include media-breakpoint-up(lg) {
				display: flex !important;
			}
		}
	}
}

// Animation
.welcome-slider-hl,
.welcome-slider-teaser,
.welcome-slider-btn {
	transition: opacity 300ms ease;
}
.hide-elements {
	.welcome-slider-hl,
	.welcome-slider-teaser,
	.welcome-slider-btn {
		opacity: 0;
	}
}

// ######################################################

.module-news-slider {

	.news-slider {
		margin: {
			top: remcalc(30px);
			bottom: remcalc(80px);
		}

		> div {
			display: none;

			&:nth-child(1),
			&:nth-child(2) {
				display: block;
			}
		}

		.news-item {
			margin-bottom: remcalc(25px);

			a {
				text-decoration: none;
				color: $body-color;
				h2, .h2,
				h3, .h3,
				h4, .h4,
				h5, .h5 {
					transition: color 200ms ease-in-out;
				}

				@include hover-state {
					color: $orange;

					h2, .h2,
					h3, .h3,
					h4, .h4,
					h5, .h5 {
						color: $orange;
					}
				}
			}
		}

		.media-body {
			padding-right: remcalc(20px);
		}

		.media-body-desc {
			display: none;
		}
		.slick-next,
		.slick-prev {
			transition: color 300ms ease-in-out;
			position: absolute;
			bottom: remcalc(-55px);
			appearance: none;
			border: none;
			background: transparent;
			color: map-get($theme-colors, secondary);
			padding: remcalc(15px);
			font-size: remcalc(30px);
			cursor: pointer;
			opacity: 1;

			&.slick-disabled {
				cursor: default;
				opacity: 0.4;
				@include hover-state {
					cursor: default;
				}
			}

			@include hover-state {
				outline: none;
				color: lighten( $orange, 10%);
			}

		}

		.slick-prev {
			right: remcalc(60px);
			z-index: 1;
		}

		.slick-next {
			right: 0;
		}
	}


	@include media-breakpoint-up(sm) {


		.news-slider {
			position: relative;
			padding: {
				left: remcalc(60px);
				right: remcalc(60px);
			}
			margin: {
				bottom: remcalc(40px);
			}

			> div {
				display: block;
			}

			.news-item {
				margin-bottom: 0;
			}

			.media-body {
				padding-right: 0;
			}

			.media-body-desc {
				display: block;
				margin-bottom: remcalc(8px);
			}

			.slick-next,
			.slick-prev {
				top: 50%;
				transform: translateY(-50%);
			}

			.slick-prev {
				left: 0;
				right: auto;
			}
		}
	}

	@include media-breakpoint-up(md) {

		.news-slider {

			margin: {
				top: remcalc(40px);
				bottom: remcalc(40px);
			}

			.media-body {
				padding-right: remcalc(30px);
			}
		}


	}
}

.content-slider {
	overflow: hidden;

	&.slick-initialized {
		> div {
			display: block;
		}
	}

	> div {
		display: none;
		&:first-child {
			display: block;
		}
	}

	.slick-next,
	.slick-prev {
		transition: opacity 200ms ease-in-out;
		cursor: pointer;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		background-color: $orange;
		color: $white;
		appearance: none;
		height: remcalc(74px);
		width: remcalc(74px);
		border-radius: 100%;
		border: none;
		padding: 0;

		> span {
		    position: absolute;
		    top: 49%;
		    font-size: 22px;
		    transform: translateY(-50%);
		}

		&:hover,
		&:active {
			opacity: 0.7;
			outline: none;
		}

	}

	.slick-next {
		right: remcalc(-37px);

		> span {
			left: remcalc(16px);
		}
	}

	.slick-prev {
		left: remcalc(-37px);
		z-index: 1;

		> span {
			right: remcalc(16px);
		}
	}

	.slick-dots {
		@extend %slick-dots;
		position: absolute;
		bottom: remcalc(20px);
		left: 50%;
		transform: translateX(-50%);

		li {

			button {

				&::before {
					background-color: $white;
				}
			}
		}
	}
}