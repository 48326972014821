/* poppins-regular - latin*/
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../Fonts/poppins-v5-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Poppins Regular'), local('Poppins-Regular'),
       url('../Fonts/poppins-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../Fonts/poppins-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../Fonts/poppins-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../Fonts/poppins-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../Fonts/poppins-v5-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-medium - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('../Fonts/poppins-v5-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Poppins Medium'), local('Poppins-Medium'),
       url('../Fonts/poppins-v5-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../Fonts/poppins-v5-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../Fonts/poppins-v5-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../Fonts/poppins-v5-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../Fonts/poppins-v5-latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-semibold - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('../Fonts/poppins-v5-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
       url('../Fonts/poppins-v5-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../Fonts/poppins-v5-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../Fonts/poppins-v5-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../Fonts/poppins-v5-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../Fonts/poppins-v5-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
}