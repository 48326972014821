a {
	transition: color 200ms ease-in-out;
}

p {
	margin-bottom: remcalc(32px);

	&.intro {
		font-size: remcalc(20px);
	}
}

ul {
	margin-bottom: remcalc(32px);
}

.date {
	color: $gray-500;
	font-size: remcalc(12px);
}

// h1, .h1 {
// 	margin-bottom: remcalc(28px);
// }

// h2, .h2 {
// 	margin-bottom: remcalc(24px);
// }

// h3, .h3 {
// 	margin-bottom: remcalc(20px);
// }


// h4, .h4 {
// 	margin-bottom: remcalc(16px);
// }

h5, .h5 {
	margin-bottom: remcalc(8px);
	font-weight: 600;
}

.primary {
	color: map-get($theme-colors, primary);
}