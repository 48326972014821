.media-modal {

	.modal-body {
		padding: 0;
	}

	.close-modal {
		transition: all 200ms ease-out;
		cursor: pointer;
		position: absolute;
		right: 0;
		top: remcalc(-55px);
		padding: 0;
		appearance: none;
		background-color: $orange;
		height: remcalc(50px);
		width: remcalc(50px);
		border-radius: 100%;
		border: none;

		span {
			display: block;
    		margin-left: 1px;
			color: $white;
		}

		@include hover-state {
			box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.4);
		}
	}

	@include media-breakpoint-up(md) {

		.close-modal {
			right: remcalc(-40px);
			top: remcalc(-60px);
		}
	}
    @include media-breakpoint-up(md) {

		.modal-dialog {
			max-width: remcalc(700px);
		}
	}

	@include media-breakpoint-up(xl) {

		.modal-dialog {
			max-width: remcalc(1000px);
		}
	}
}