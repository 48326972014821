.noscript-msg {
    left: 0;
    width: 100%;
    z-index: 9999;
    background: #ff9;
    font-size: 14px;
    line-height: 20px;
    padding: 15px;
    text-align: center;
    position: fixed;
    bottom: 0;
}