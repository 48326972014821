$tile-desc-bg-color: #e5f1f3;

.tile-wrap {
    @include make-col-ready;
    margin-bottom: ( $grid-gutter-width / 2 );

    @include media-breakpoint-up(ss) {
        @include make-col(6);
        margin-bottom: $grid-gutter-width;
    }

    @include media-breakpoint-up(md) {
        @include make-col(4);
    }
}


.tile {
    @include clearfix();
    display: block;
    text-decoration: none;
    height: 100%;
    background-color: $tile-desc-bg-color;
    position: relative;

    .tile-desc {
        transition: color 200ms ease-in-out;
        background-color: $tile-desc-bg-color;
        margin-bottom: 0;
        padding: {
            top: remcalc(15px);
            right: remcalc(25px);
            bottom: remcalc(15px);
            left: remcalc(25px);
        }
    }

    @include hover-state {

        text-decoration: none;
    }

    &.tile-distributor {

        .tile-desc {
            padding: {
                top: remcalc(15px);
                right: remcalc(25px);
                bottom: remcalc(75px);
                left: remcalc(25px);
            }
        }

        .btn-arrow {
            position: absolute;
            bottom: remcalc(15px);
            right: remcalc(25px);
        }

        .tile-thumb {
            text-align: center;
        }

        @include hover-state {
            cursor: pointer;

            .btn-arrow {
                box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.4);
            }

            .tile-desc {
                color: lighten( map-get($theme-colors, primary) , 10%);
            }
        }
    }

    &.tile-video {

        .tile-thumb {
            position: relative;

            &::after {
                transition: box-shadow 200ms ease-in-out;
                content: "";
                height: remcalc(60px);
                width: remcalc(60px);
                border-radius: 100%;
                background-image: url("../Images/mediathek-play.svg");
                background-size: 100%;
                background-repeat: no-repeat;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        @include hover-state {
            cursor: pointer;

            .tile-thumb {

                &::after {
                    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.4);
                }
            }

            .tile-desc {
                color: lighten( map-get($theme-colors, primary) , 10%);
            }
        }
    }
    &.tile-pdf {

        .tile-thumb {
            position: relative;

            &::after {
                transition: box-shadow 200ms ease-in-out;
                content: "";
                height: remcalc(60px);
                width: remcalc(60px);
                border-radius: 100%;
                background-image: url("../Images/mediathek-download.svg");
                background-size: 100%;
                background-repeat: no-repeat;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        @include hover-state {
            cursor: pointer;

            .tile-thumb {

                &::after {
                    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.4);
                }
            }

            .tile-desc {
                color: lighten( map-get($theme-colors, primary) , 10%);
            }
        }
    }

    &.tile-person {
        padding: remcalc(20px);
    }
}








.download-tile-wrap {
    @include make-col-ready;
    margin-bottom: ( $grid-gutter-width / 2 );

    @media (min-width: 400px) {
        @include make-col(6);
        margin-bottom: $grid-gutter-width;
    }

    @include media-breakpoint-up(sm) {
        @include make-col(4);
    }

    @include media-breakpoint-up(lg) {
        @include make-col(3);
    }
}

.download-tile {
    display: block;
    background-color: $tile-desc-bg-color;
    padding: remcalc(20px);
    height: 100%;
    text-decoration: none;
    text-align: center;

    .download-tile-thumb {
        text-align: center;
        margin-bottom: remcalc(15px);
    }

    .download-tile-desc {
        transition: color 200ms ease-in-out;
        font-size: remcalc(16px);
        margin-bottom: 0;
    }

    @include hover-state {
        text-decoration: none;
        .download-tile-desc {
           color: $link-hover-color;
        }
    }

    @media (min-width: 400px) {
        text-align: left;
        .download-tile-desc {
            font-size: remcalc(18px);
        }
    }
}

.orange-tile {

    .orange-tile-head {
        background-color: map-get($theme-colors, secondary);
        padding: remcalc(21px);
        line-height: 1.3;
        font-weight: 500;
        color: $white;

        h2, .h2,
        h3, .h3,
        h4, .h4,
        h5, .h5 {
            font-size: remcalc(24px);
            color: $white;
            margin-bottom: 0;
        }

        @include media-breakpoint-up(md) {

            h2, .h2,
            h3, .h3,
            h4, .h4,
            h5, .h5 {
                font-size: remcalc(20px);
            }
        }

        @include media-breakpoint-up(lg) {
            h2, .h2,
            h3, .h3,
            h4, .h4,
            h5, .h5 {
                font-size: remcalc(24px);
            }
        }
    }

    .orange-tile-body {
        background-color: rgba(#ed963b, 0.15);
        padding: remcalc(30px);

        > * {

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .orange-tile-body-item {
        border-bottom: 1px solid rgba(0, 121, 136, 0.1);
        padding: {
            top: remcalc(20px);
            bottom: remcalc(20px);
        }

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
            border-bottom: 0;
        }
    }
}





.vcard-tile-wrap {
    @include make-col-ready;
    margin-bottom: ( $grid-gutter-width / 2 );

    @media (min-width: 400px) {
        @include make-col(6);
        margin-bottom: $grid-gutter-width;
    }

    @include media-breakpoint-up(sm) {
        @include make-col(4);
    }

    @include media-breakpoint-up(lg) {
        @include make-col(3);
    }
}

.tile-person {
    text-align: center;

    .role {
        display: block;
    }

    .tel {
        display: block;
        text-decoration: none;
    }

    @media (min-width: 400px) {
        text-align: left;
    }
}