.vcard {

	&.organisation {
		margin-top: remcalc(32px);

		.adr,
		.tel,
		.mail {
			display: block;
			margin-bottom: remcalc(26px);
			position: relative;
			padding-left: remcalc(50px);

			&::before {
				position: absolute;
				left: 0;
				top: 0;
				display: block;
				font-size: remcalc(21px);
				color: map-get($theme-colors, primary);

				font-family: 'icomoon' !important;
			    speak: none;
			    font-style: normal;
			    font-weight: normal;
			    font-variant: normal;
			    text-transform: none;
			    line-height: 1;
			    -webkit-font-smoothing: antialiased;
			    -moz-osx-font-smoothing: grayscale;
			}
		}

		.adr {

			&::before {
				content: "\e908";
			}

			.org,
			.street-adress {
				display: block;
			}
		}

		.tel {

			&::before {
				content: "\e90a";
			}

			.type {
				display: block;
			}
		}

		.mail {
			font-size: remcalc(14px);

			&::before {
				content: "\e909";
			}

			.email {
				display: block;
				font-weight: 600;
			}
		}

		@include media-breakpoint-up(sm) {
			margin-top: 0;

			.mail {
				font-size: remcalc(16px);
			}
		}
	}

	&.person {

		.photo {
			margin-bottom: remcalc(20px);
		}

		.fn {
			margin-bottom: remcalc(9px);
		}
	}
}