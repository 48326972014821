.btn-arrow {
	position: relative;
	transition: all 200ms ease-out;
	display: block;
	height: remcalc(45px);
	width: remcalc(45px);
	background-image: url("../Images/button-arrow.svg");
	background-size: remcalc(45px) remcalc(45px);
	background-position: center center;
	border-radius: 100%;
	background-color: $orange;

	@include hover-state {
		box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.4);
	}
}

.btn {
	color: $white;
	transition: all 200ms ease-in-out;
	text-decoration: none;
	white-space: normal;
    max-width: 100%;
    word-wrap: break-word;
	margin: {
		right: remcalc(8px);
		bottom: remcalc(8px);
	}

	@include hover-state {
		box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.4) !important;
	}
}

.btn-secondary {

	@include hover-state {
		background-color: lighten( $orange, 5%);
		border-color: lighten( $orange, 10%);
	}
}

.btn-primary {

	@include hover-state {
		background-color: lighten( #007988, 5%);
		border-color: lighten( #007988, 10%);
	}
}

$btt-hw: 45px;

.btt-wrap {
	position: absolute;
    z-index: 50;
    transform: translateX(-50%);
    width: remcalc($btt-hw);
    height: remcalc($btt-hw);
    top: remcalc(-25px);
    left: 50%;
}

.btt {
	transition: transform 200ms ease-in-out, background-color 200ms ease-in-out, box-shadow 200ms ease-in-out;
	position: absolute;
	display: block;
	width: remcalc($btt-hw);
	height: remcalc($btt-hw);
	border-radius: 100%;
	background-color: $orange;
	color: $white;
	font-size: remcalc(25px);
	text-decoration: none;

	&::before {
		font-size: remcalc(17px);
		content: "";
		display: block;
		position: absolute;
		top: 47%;
		left: 48%;
		transform: translate(-50%, -50%) rotate(-90deg);
		border-radius: 100%;

		font-family: 'icomoon' !important;
	    speak: none;
	    font-style: normal;
	    font-weight: normal;
	    font-variant: normal;
	    text-transform: none;
	    line-height: 1;
	    -webkit-font-smoothing: antialiased;
	    -moz-osx-font-smoothing: grayscale;
	    content: "\e904";

	}

	&:hover {
		text-decoration: none;
		color: $white;
		background-color: lighten( $orange, 5%);
		box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.4) !important;
	}
}