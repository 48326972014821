.welcome {
	.module {
		padding: {
			top: remcalc(40px);
			bottom: remcalc(40px);
		}

		+ .module {
			padding-top: remcalc(40px);
		}

		@include media-breakpoint-up(md) {
			padding: {
				top: remcalc(60px);
				bottom: remcalc(60px);
			}

			+ .module {
				padding-top: remcalc(60px);
			}
		}
	}
}

.module {
	padding: {
		top: remcalc(40px);
		bottom: remcalc(40px);
	}

	+ .module {
		padding-top: 0;
	}

	> * {
		&:last-child {
			margin-bottom: 0;
		}
	}

	@include media-breakpoint-up(md) {
		padding: {
			top: remcalc(60px);
			bottom: remcalc(60px);
		}
	}

	&.module-welcome-slider {
		padding: {
			top: 0;
			bottom: 0;
		}
	}

	&.module-distributor {
		padding: {
			bottom: 0;
		}

		@include media-breakpoint-up(md) {
			padding: {
				bottom: remcalc(20px);
			}
		}
	}
}

.tx-kwleseclubs {
	.module-standort-suche {
		padding-top: 0;
	}
}

.module-welcome-slider {
	position: relative;
}

.module-intro {
	background-color: map-get($theme-colors, primary);
	background-image: url('../Images/bg-pattern.png');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;

	* {
		color: $white;
	}

	.z-index-2 {
		position: relative;
		z-index: 2;
	}
	.text-orga {
		max-width: 480px;
	}
	.distributor-orga {
		max-width: 410px;

		.distributor-img {
			img {
				min-height: 65px;
				width: auto;
			}
		}

		.distributor-item {
			background-color: rgba(0, 85, 138, 0.9);

			.btn-arrow {
				width: 37px;
				height: 37px;
			}
		}
	}

	.bg-img {
		position: absolute;
		justify-content: center;
		align-items: center;
		z-index: 0;
		height: 100%;
		width: 100%;
		img {
			max-width: 260px;
			width: 100%;
		}
	}
}

.module-distributor {
	background-color: $white;

	.distributor-item {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: remcalc(40px);
	}

	.distributor-img {
		width: remcalc(50px);
		min-width: remcalc(50px);
		margin-right: remcalc(32px);
	}

	.distributor-desc {
		width: 100%;
		margin-right: remcalc(32px);

		> * {
			font-size: remcalc(16px);
			margin-bottom: 0;
		}
	}

	@include media-breakpoint-up(sm) {
		.distributor-desc {
			> * {
				font-size: remcalc(20px);
			}
		}
	}

	@include media-breakpoint-up(sm) {
		.distributor-img {
			width: remcalc(65px);
			min-width: remcalc(65px);
		}

		.distributor-desc {
			> * {
				font-size: remcalc(24px);
			}
		}
	}

	@include media-breakpoint-up(md) {
		text-align: center;

		.distributor-item {
			flex-direction: column;
			justify-content: space-between;
		}

		.distributor-img {
			position: relative;
			width: auto;
			width: 100%;
			height: remcalc(100px);
			min-height: remcalc(100px);
			margin: {
				right: 0;
				bottom: remcalc(24px);
			}

			img {
				max-height: 100%;
				max-width: 100%;
				width: auto;
				height: auto;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
			}
		}

		.distributor-desc {
			margin: {
				bottom: remcalc(24px);
				right: 0;
			}

			> * {
				font-size: remcalc(28px);
			}
		}

		.distributor-link {
			.btn-arrow {
				margin: 0 auto;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		.distributor-desc {
			min-height: remcalc(66px);
		}
	}
}

.module-news-slider {
	background-color: #fefef4;
	border: {
		top: solid 1px rgba(0, 121, 136, 0.1);
		bottom: solid 1px rgba(0, 121, 136, 0.1);
	}
}

.module-flockler {
	background-color: map-get($theme-colors, primary);

	h1,
	h2,
	h3,
	h4,
	.h1,
	.h2,
	.h3,
	.h4 {
		color: $white;
	}
}

.module-mediathek-videos {
	.pagination {
		button {
			color: #007988;
			background: transparent;
			border: none;
			cursor: pointer;
		}
		.active-page {
			font-weight: bold;
			text-decoration: underline;
		}
	}

	background-color: $white;

	+ .module-mediathek-videos {
		padding-top: 0;
	}
}

.module-ideen {
	background-color: $white;

	+ .module-ideen {
		padding-top: 0;
	}
}

.module-media {
	+ .module-text {
		padding: {
			top: 0;
		}
	}

	+ .module-media {
		padding: {
			top: 0;
		}
	}
}

.module-text {
	+ .module-accordion {
		padding: {
			top: 0;
		}
	}
}

.module-bild-textfloat {
	@include clearfix;
	display: flex;
	flex-direction: column;

	.img-float {
		margin-bottom: remcalc(20px);
	}

	.mobile-top {
		order: -1;
	}

	@include media-breakpoint-up(sm) {
		display: block;
		flex-direction: initial;

		.img-float {
			max-width: 50%;

			&.right {
				float: right;
				margin: {
					left: remcalc(20px);
					bottom: remcalc(20px);
				}
			}

			&.left {
				float: left;
				margin: {
					right: remcalc(20px);
					bottom: remcalc(20px);
				}
			}
		}
	}
}

.module-accordion {
	+ .module-text {
		padding: {
			top: 0;
		}
	}

	+ .module-accordion {
		padding: {
			top: 0;
		}
	}
}

.module-materialien {
	padding-top: 0;
}

.module-news {
	.pagination {
		button {
			color: #007988;
			background: transparent;
			border: none;
			cursor: pointer;
		}
		.active-page {
			font-weight: bold;
			text-decoration: underline;
		}
	}
	.news-item {
		padding: {
			top: remcalc(30px);
			bottom: remcalc(14px);
		}

		.media-body {
			> *:last-child {
				margin-bottom: 0;
			}
			.text-snippet {
				display: -webkit-box;
				-webkit-line-clamp: 8;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.news-item-img {
			display: none;
		}

		&:last-child {
			border-bottom: none;
		}
	}

	.readmore-news-link {
		margin-left: 0;
		position: relative;
		font-weight: 600;
	}

	.leseclubs-icon,
	.media-labs-icon {
		.readmore-news-link {
			position: relative;

			&::after {
				content: '';
				position: absolute;
				right: 0;
				bottom: 0;
				display: block;
				height: remcalc(30px);
				width: remcalc(75px);
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center center;
			}
		}
	}

	.leseclubs-icon {
		.readmore-news-link {
			&::after {
				background-image: url('../Images/leseclubs-logo.png');
			}
		}
	}

	.media-labs-icon {
		.readmore-news-link {
			&::after {
				background-image: url('../Images/medialabs-logo.png');
			}
		}
	}

	@include media-breakpoint-up(sm) {
		.news-item {
			.news-item-img {
				display: block;
			}
		}

		.readmore-news-link {
			position: relative;
			padding-top: remcalc(8px);
		}
	}
}

.news-module {
	.leseclubs-icon {
		.readmore-news-link {
			&::after {
				background-image: url('../Images/leseclubs-logo.png');
			}
		}
	}

	.leseclubs-icon,
	.media-labs-icon {
		.readmore-news-link {
			position: relative;

			&::after {
				content: '';
				position: absolute;
				right: 0;
				bottom: 0;
				display: block;
				height: remcalc(30px);
				width: remcalc(75px);
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center center;
			}
		}
	}

	.media-labs-icon {
		.readmore-news-link {
			&::after {
				background-image: url('../Images/medialabs-logo.png');
			}
		}
	}
	.tb-padding {
		padding: remcalc(90px) 0px remcalc(40px) 0px;
	}
	.news-titel {
		max-width: remcalc(730px);
	}
	.date {
		font-size: remcalc(16px);
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 2.13;
		letter-spacing: normal;
		color: #acb5bd;
	}
	.tags {
		background-color: #e5f1f3;
		border-radius: remcalc(6px);
		background-color: #e5f1f3;

		p {
			font-size: remcalc(12px);
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 2;
			letter-spacing: remcalc(0.11px);
			color: #007988;
			padding: remcalc(2px) remcalc(8px);
		}
	}

	.bodytext {
		max-width: remcalc(1025px);
	}

	.more-artikel-container {
		display: flex;
		justify-content: space-between;
		width: 100%;
		border-bottom: solid 1px rgba(0, 121, 136, 0.2);
		padding-top: 1.875rem;
		padding-bottom: 0.875rem;
	}

	.news-item {
		padding: {
			top: remcalc(30px);
			bottom: remcalc(14px);
		}
		.readmore-news-link {
			font-weight: bold;
		}

		.media-body {
			min-width: remcalc(360px);
			> *:last-child {
				margin-bottom: 0;
			}
			.text-snippet {
				margin-bottom: remcalc(12px);
				display: -webkit-box;
				-webkit-line-clamp: 8;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}
