$error-color: #ed1c24;

.form-group {
	margin-bottom: remcalc(24px);
	position: relative;
}

.form-control {

	&.parsley-error {
		border-color: $error-color;
	}

	&.parsley-success {
		border-color: #1bb934;
	}
}

.form-check {
	&.parsley-error {
		color:  $error-color;
	}
}

.parsley-errors-list {
	position: absolute;
	margin: 0;
	padding-left: 0;
	list-style: none;
	font-size: remcalc(12px);
	color: $error-color;
}

.map-search {
	max-width: remcalc(400px);
}

.placeholder {
	color: $input-placeholder-color;
}
select {
	option {
		color: #555;

		&:first-child {
			color: $input-placeholder-color;
			display: none;
		}
	}
}