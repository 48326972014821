@charset "utf-8";

@import "widgets/font";

// Custom Functions
@import "functions";

// Override Bootstrap Variables
@import "variables";

// Custom Mixins
@import "mixins";

// Bootstrap aus bower_components
@import "../../scripte/node_modules/bootstrap/scss/functions";
@import "../../scripte/node_modules/bootstrap/scss/variables";
@import "../../scripte/node_modules/bootstrap/scss/mixins";
@import "../../scripte/node_modules/bootstrap/scss/print";
@import "../../scripte/node_modules/bootstrap/scss/reboot";
@import "../../scripte/node_modules/bootstrap/scss/type";
@import "../../scripte/node_modules/bootstrap/scss/images";
// @import "../../scripte/node_modules/bootstrap/scss/code";
@import "../../scripte/node_modules/bootstrap/scss/grid";
// @import "../../scripte/node_modules/bootstrap/scss/tables";
@import "../../scripte/node_modules/bootstrap/scss/forms";
@import "../../scripte/node_modules/bootstrap/scss/buttons";
@import "../../scripte/node_modules/bootstrap/scss/transitions";
@import "../../scripte/node_modules/bootstrap/scss/dropdown";
// @import "../../scripte/node_modules/bootstrap/scss/button-group";
@import "../../scripte/node_modules/bootstrap/scss/input-group";
// @import "../../scripte/node_modules/bootstrap/scss/custom-forms";
@import "../../scripte/node_modules/bootstrap/scss/nav";
@import "../../scripte/node_modules/bootstrap/scss/navbar";
@import "../../scripte/node_modules/bootstrap/scss/card";
@import "../../scripte/node_modules/bootstrap/scss/breadcrumb";
// @import "../../scripte/node_modules/bootstrap/scss/pagination";
// @import "../../scripte/node_modules/bootstrap/scss/badge";
// @import "../../scripte/node_modules/bootstrap/scss/jumbotron";
// @import "../../scripte/node_modules/bootstrap/scss/alert";
// @import "../../scripte/node_modules/bootstrap/scss/progress";
@import "../../scripte/node_modules/bootstrap/scss/media";
// @import "../../scripte/node_modules/bootstrap/scss/list-group";
// @import "../../scripte/node_modules/bootstrap/scss/close";
@import "../../scripte/node_modules/bootstrap/scss/modal";
// @import "../../scripte/node_modules/bootstrap/scss/tooltip";
// @import "../../scripte/node_modules/bootstrap/scss/popover";
// @import "../../scripte/node_modules/bootstrap/scss/carousel";
@import "../../scripte/node_modules/bootstrap/scss/utilities";

//Slick SCSS
@import "../../scripte/node_modules/slick-carousel/slick/slick.scss";

// ICOMOON CSS
@import "../../Public/icomoon/style.css";

// Widgets
// @import "widgets/z-index";
@import "widgets/typo";
@import "widgets/animate";
@import "widgets/layout";
@import "widgets/modules";
@import "widgets/slider";
@import "widgets/buttons";
@import "widgets/header";
@import "widgets/footer";
@import "widgets/tiles";
@import "widgets/accordion";
@import "widgets/lists";
@import "widgets/modal";
@import "widgets/forms";
@import "widgets/vcard";
@import "widgets/tabs";
@import "widgets/maps";
@import "widgets/flockler";
@import "widgets/banny";

//Print Styles
// @import "widgets/print";

//Noscript Styles
@import "widgets/noscript";