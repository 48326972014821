* {
	outline: none;

	&:hover,
	&:focus,
	&:active {
		outline: none !important;
	}
}

iframe {
	border: 0 !important;
}

.ackbar,
.hidden {
	display: none;
}

.page-wrap {
	width: 100%;
	max-width: remcalc(1337px);
	margin-right: auto;
	margin-left: auto;
}

.container-fluid {
	max-width: remcalc(1160px);
}

.multi-border {
	display: flex;

	> div {
		flex-grow: 1;
		height: remcalc(3px);

		&:nth-child(1) {
			background-color: $purple;
		}

		&:nth-child(2) {
			background-color: $blue;
		}

		&:nth-child(3) {
			background-color: $teal;
		}

		&:nth-child(4) {
			background-color: $red;
		}

		&:nth-child(5) {
			background-color: $orange;
		}

		&:nth-child(6) {
			background-color: $blue;
		}
	}
}

.img-col-right {
	margin-top: remcalc(24px);
}

.border-wrap {
	padding-left: remcalc(20px);
	border-left: 1px solid #e5f0ea;
}

.content-wrap {
	background-color: $white;
}

.error {
	.content-wrap {
		padding-bottom: remcalc(80px);
	}

	.footer {
		&::before {
			color: map-get($theme-colors, primary);
			position: absolute;
			top: -109px;
			right: remcalc(15px);
			content: '404';
			display: block;
			font-size: 100px;
		}
	}

	@include media-breakpoint-up(xl) {
		.footer {
			&::before {
				top: -152px;
				right: 100px;
				font-size: 140px;
			}
		}
	}
}

.readmore {
	position: relative;
	transition: height 200ms;
	overflow: hidden;
	padding-bottom: remcalc(40px);

	&[id^='rmjs'] {
		padding-bottom: 0;

		&[aria-expanded='true'] {
			&::after {
				opacity: 0;
				z-index: -1;
			}
		}

		&::after {
			transition: opacity 200ms ease-in-out;
			opacity: 1;
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			height: remcalc(50px);
			width: 100%;
			box-shadow: inset 0 -100px 60px -60px white;
		}
	}

	@include media-breakpoint-up(sm) {
		&[id^='rmjs'] {
			&::after {
				left: remcalc(192px);
			}
		}
	}
}

.readmore-news-link {
	position: relative;
	display: block;
	width: auto;
	margin-top: remcalc(8px);
	margin-bottom: remcalc(32px);
}
.module-news {
	padding-top: 0;
}
.schlagwoerter-filters-bg {
	background-color: #d6ecf0;

	.schlagwoeter-filter-button {
		color: #738392;
		margin-right: 10px;

		.checkbox-button {
			border: 1px solid #738392;
			display: inline-block;
			padding: 0.375rem 0.75rem;
			font-size: 1rem;
			border-radius: 0.25rem;
			text-align: center;
			cursor: pointer;
			transition: background-color 0.3s, color 0.3s;
			text-decoration: none;
			margin-bottom: 0;
		}

		.checkbox-button:hover {
			background-color: #007988;
			color: #d6ecf0;
			border-color: #007988;
		}

		input[type='checkbox'] {
			display: none;
		}

		.checkbox-button.active {
			background-color: #007988;
			color: #d6ecf0;
			border-color: #007988;
		}
		.schlagwoerter-closer {
			display: none;
		}
	}
}

.newsletter-embed {
	position: relative;

	&::after {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		content: '';
		height: 100%;
		width: 20px;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
		background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(
			to right,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 1) 100%
		); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
	}

	&::before {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		content: '';
		height: 100%;
		width: 20px;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100 */
		background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(
			to right,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0) 100%
		); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
	}

	> div {
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;

		iframe {
			width: 100% !important;
			max-width: 100%;
			min-width: 100%;
		}
	}
}
