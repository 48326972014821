.flockler-posts {
	margin: 0 auto;
	max-width: remcalc(1160px);

	@include media-breakpoint-up(lg) {

		.flockler-wall-item {
			width: 33.333%;
		}
	}

	@include media-breakpoint-up(xl) {

		.flockler-wall-item {
			width: 25%;
		}
	}

	.flockler-wall-item__content {
		border: 0;
		margin: remcalc(15px);
	}

	.flockler-wall-item__body {

		a {
			text-decoration: none;
			color: $link-color;

			@include hover-state {
				color: $link-hover-color;
			}
		}
	}

	.flockler-wall-item__profile__body {
		a {
			@include hover-state {
				text-decoration: none;
			}
		}
	}

	.flockler-wall-item__share-actions {
		a {
			transition: color 300ms ease-in-out;
		}
	}
}

.flockler-btn-load-more {
	border-radius: remcalc(4px) !important;
    padding: .375rem .75rem !important;
	text-decoration: none;
	background-color: #ed963b !important;
    border-color: #ed963b !important;
	color: $white !important;
	transition: all 200ms ease-in-out;
	text-decoration: none;
	white-space: normal;
    max-width: 100%;
    word-wrap: break-word;
	margin: {
		right: remcalc(8px);
		bottom: remcalc(8px);
	}

	@include hover-state {
		text-decoration: none !important;
		box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.4) !important;
		background-color: lighten( $orange, 5%) !important;
		border-color: lighten( $orange, 10%) !important;
	}
}