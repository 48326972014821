.accordion {

	.card {
		border: none;
	}

	.card-header {
		border: none;
		border-radius: 0;
		padding: 0;
		background-color: map-get($theme-colors, primary);
		border-bottom: 2px solid $white;

		button {
			transition: background-color 200ms ease-in-out;
			cursor: pointer;
			width: 100%;
			position: relative;
			appearance: none;
			border: none;
			background-color: map-get($theme-colors, primary);
			color: $white;
			text-align: left;
			font: {
				weight: 400;
				size: remcalc(18px);
			}
			padding: {
				top: remcalc(20px);
				right: remcalc(55px);
				bottom: remcalc(20px);
				left: remcalc(20px);
			}

			&::after {
				position: absolute;
				right: remcalc(20px);
				top: remcalc(20px);
				font-family: 'icomoon' !important;
			    speak: none;
			    font-style: normal;
			    font-weight: normal;
			    font-variant: normal;
			    text-transform: none;
			    line-height: 1;
			    -webkit-font-smoothing: antialiased;
			    -moz-osx-font-smoothing: grayscale;
			    content: "\e900";
			}

			&.collapsed {
				background-color: #e5f1f3;
				color: map-get($theme-colors, primary);

				&::after {
					color: map-get($theme-colors, secondary);
				    content: "\e901";
				}

				&:hover,
				&:active {
					background-color: darken(#e5f1f3,5%);
				}
			}
		}
	}

	.card-body {

		.module {
			padding-top: 0;
		}
	}

	@include media-breakpoint-up(sm) {

		.card-header {

			button {
				padding: {
					right: remcalc(90px);
					left: remcalc(40px);
				}
				font: {
					weight: 500;
					size: remcalc(20px);
				}
			}
		}
	}
}