header {
	background: $white;

	@include media-breakpoint-up(md) {

		+ .content-wrap {
			border: {
				top: solid 1px #e5f1f3;
			}
		}

		+ .module-welcome-slider {
			border: {
				top: solid 1px #e5f1f3;
			}
		}
	}
}

.top-header {
	@include clearfix;
	width: 100%;
	margin: 0 auto;

	@include media-breakpoint-up(md) {
		padding: {
			top: remcalc(45px);
			bottom: remcalc(20px);
		}
	}
}

.leseclubs-logo {
	height: remcalc(52px);

	.top-header & {
		display: inline-block;
	}

	@include media-breakpoint-up(md) {
		height: remcalc(83px);
		margin-right: remcalc(40px);
	}
}

.medialabs-logo {
	height: remcalc(36px);

	.top-header & {
		display: inline-block;
	}

	@include media-breakpoint-up(md) {
		height: remcalc(50px);
	}
}

.kms-logo {
	height: remcalc(65px);

	.top-header & {
		display: inline-block;
	}

	@include media-breakpoint-up(md) {
		height: remcalc(130px);
	}
}

.stiftung-lesen-logo {
	display: none;

	@include media-breakpoint-up(md) {
		display: inline-block;
		height: remcalc(50px);
	}
}

.navbar {
	padding: 0;
	background-color: #f7f8f9;

	.navbar-brand {
		padding: {
			top: remcalc(15px);
			bottom: remcalc(15px);
		}

		&:first-child {
			margin-left: remcalc(15px);
		}
	}

	.navbar-toggler {
		border: none;

		.icon-bar {
			transition: all 300ms ease;
			position: relative;
		    width: 30px;
		    height: 2px;
		    border-radius: 0;
		    display: block;
		    background-color: map-get($theme-colors, primary);

			.icon-bar:nth-of-type(1) {
			    top: 0;
			}

			.icon-bar:nth-of-type(2) {
			    top: 0;
			    left: 0;
			}

			.icon-bar:nth-of-type(3) {
			    bottom: 0;
			}

			+ .icon-bar {
				margin-top: 7px;
			}
		}
	}

	.navbar-toggler:not(.collapsed) {

		.icon-bar:nth-of-type(1) {
		    top: 9px;
		    transform: rotate(45deg);
		}

		.icon-bar:nth-of-type(2) {
		    opacity: 0;
		}

		.icon-bar:nth-of-type(3) {
		    top: -9px;
		    transform: rotate(-45deg);
		}
	}


	.navbar-collapse {
		background-color: map-get($theme-colors, primary);

		.navbar-nav {
			font-weight: 600;
			padding: {
				top: remcalc(15px);
				right: remcalc(15px);
				bottom: remcalc(15px);
				left: remcalc(15px);
			}

			.nav-item {

				&.active {

					.nav-link {
						color: $orange;
					}
				}

				&:first-child {

					.nav-link {
						border-top: none;
					}
				}

				&.show {

					.nav-link {

						&::after {
							content: "\e900";
						}
					}
				}
			}

			.nav-link {
				display: block;
				color: $white;
				text-decoration: none;
				font-size: remcalc(22px);
				border-top: 1px solid rgba(255, 255, 255, 0.1);
				padding: {
					top: remcalc(10px);
					bottom: remcalc(10px);
				}

				&.dropdown-toggle {
					position: relative;
					padding-right: remcalc(40px);

					&::after {
						font-family: 'icomoon' !important;
					    speak: none;
					    font-style: normal;
					    font-weight: normal;
					    font-variant: normal;
					    text-transform: none;
					    line-height: 1;
					    -webkit-font-smoothing: antialiased;
					    -moz-osx-font-smoothing: grayscale;

						transition: transform 200ms ease-in-out;
						height: remcalc(22px);
						width: remcalc(22px);
						position: absolute;
						right: 0;
						top: 50%;
   						transform: translateY(-50%);
						border: none;
					    content: "\e901";
					}
				}

				@include hover-state {
					color: $orange;
					text-decoration: none;
				}
			}

			.dropdown-menu {
			    background: transparent;
				margin: 0;
				border: none;
				padding: {
					top: remcalc(10px);
					bottom: remcalc(10px);
				}
			}

			.dropdown-item {
				white-space: normal;
				font-weight: 600;
				color: $white;
				font-size: remcalc(20px);
				text-decoration: none;

				&.active {
					color: $orange;
				}

				@include hover-state {
					color: $orange;
					text-decoration: none;
					background: transparent;
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		background-color: $white;

		&::before {
			position: absolute;
			right: remcalc(7px);
			top: remcalc(-46px);
			content: "";
			display: block;
			height: remcalc(92px);
			width: remcalc(338px);
			background-image: url("../Images/silhouetten-header.svg");
			background-repeat: no-repeat;
		}

		.navbar-collapse {
			background-color: $white;
			border: {
				top: solid 1px #e5f1f3;
			}

			.navbar-nav {
				padding: 0;
				width: 100%;
    			margin: 0 auto;
    			max-width: remcalc(1130px);

				.nav-item {

					&.show {

						.nav-link {
							background-color: map-get($theme-colors, primary);
							color: $white;
							border-left: 1px solid map-get($theme-colors, primary);
							&::after {
								content: "\e903" !important;
								transform:  translateY(-50%) rotate(90deg);
							}
						}
					}

					&:first-child {

						&.show {

							.nav-link {
								border-top: 1px solid map-get($theme-colors, primary);
							}
						}
					}

					&:last-child {

						.nav-link {
							border-right: solid 1px #e5f1f3;
						}
					}
				}

				.nav-link {
					border-left: solid 1px #e5f1f3;
					color: map-get($theme-colors, primary);
					padding: {
						top: remcalc(15px);
						bottom: remcalc(15px);
						left: remcalc(24px);
					}

					&.dropdown-toggle {
						padding-right: remcalc(55px);

						&::after {
							transform: translateY(-50%) rotate(-90deg);
							content: "\e903";
						    width: remcalc(14px);
    						height: remcalc(17px);
    						font-size: remcalc(17px);
    						right: remcalc(30px);
						}
					}
				}

				.dropdown-menu {
					border-radius: 0;
					background: map-get($theme-colors, primary);
				}

				.dropdown-item {
					white-space: nowrap;
				}
			}
		}
	}

	@include media-breakpoint-up(xl) {

		&::before {
			right: remcalc(95px);
		}
	}
}


.subpage-header {
	padding: remcalc(35px) 0;
	background-color: map-get($theme-colors, primary);
	background-image: url("../Images/bg-pattern.png");
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;

	* {
		color: $white;
	}

	h1,.h1,
	h2,.h2,
	h3,.h3 {
		margin-bottom: 0;
	}

	@include media-breakpoint-up(md) {
		padding: remcalc(45px) 0;
	}
}