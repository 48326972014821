.nav-tabs {

	.nav-link {
		font-weight: 600;
		text-decoration: none;
	}

	.nav-item {
		border: 1px solid map-get($theme-colors, primary);

		&.active {
			border: 1px solid map-get($theme-colors, primary);
		}

		@include hover-state {
			border: 1px solid map-get($theme-colors, primary);
		}
	}
}


.purple-marker,
.red-marker {
	position: relative;
	padding: {
		top: remcalc(5px);
		right: remcalc(5px);
		bottom: remcalc(5px);
		left: remcalc(25px);
	}

	&::before {
		position: absolute;
		left: 0;
		top: remcalc(12px);
		content: "";
		height: remcalc(30px);
		width: remcalc(21px);
		background-repeat: no-repeat;
		background-image: url("../Images/icon-sprite.png");
	}

	&.active {
		&::before {
			animation: bounce 1s;
		}
	}
}

.purple-marker {
	&::before {
		background-position: 0 0;
	}
}

.red-marker {
	&::before {
		background-position: -22px 0;
	}
}

.map-logos {
	width: 100%;
	position: relative;
	height: remcalc(65px);
	margin-bottom: remcalc(10px);

	a {
		transition: opacity 100ms ease-in-out;
		display: block;
		position: absolute;
		bottom: remcalc(10px);
		opacity: 0.5;

		img {
			width: 70%;
			height: 70%;
		}

		&.active {
			opacity: 1;
		}

		&.purple-marker {

			&::before {
				top: remcalc(5px);
			}
		}
		&.red-marker {
			left: remcalc(145px);

			&::before {
				top: remcalc(5px);
			}
		}
	}

	@include media-breakpoint-up(sm) {
		height: auto;
		width: auto;
		margin-right: auto;
		margin-bottom: 0;

		a {
			img {
				width: auto;
				height: auto;
			}

			&.purple-marker {

				&::before {
					top: remcalc(8px);
				}
			}

			&.red-marker {
				left: remcalc(165px);

				&::before {
					top: remcalc(8px);
				}
			}
		}
	}
}
