#map {
	#content {
		padding: {
			left: remcalc(5px);
		}
	}
	.firstHeading {
		font: {
			family: "Poppins";
			weight: 600;
			size: remcalc(15px);
		}
		margin: {
		    top: 10px;
   			bottom: 5px;
		}
	}
	.address {
		margin: {
			bottom: remcalc(5px);
		}
		font: {
			family: "Poppins";
			size: remcalc(13px);
		}
	}

	@include media-breakpoint-up(md) {
		.firstHeading {
			font: {
				size: remcalc(18px);
			}
		}
		.address {
			font: {
				family: "Poppins";
				size: remcalc(16px);
			}
		}
	}
}

.embed-map {
	@include media-breakpoint-down(ss) {
		&::before {
			padding-top: 100%;
		}
	}
}