.download-list {
	padding-left: 0;
	list-style: none;
	margin-bottom: remcalc(32px);

	li {
		margin-bottom: remcalc(20px);

		&:last-child {
			margin-bottom: 0;
		}
	}

	a {
		display: inline-block;
		padding-left: remcalc(40px);
		position: relative;
		font-weight: 600;
		text-decoration: none;

		@include hover-state {
			color: map-get($theme-colors, secondary);
		}

		&::before {
			position: absolute;
			font-family: 'icomoon' !important;
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: "\f016";
			font-size: remcalc(24px);
			left: 0;
			top: remcalc(-1px);
		}

		&[href*=".pdf"],
		&[href*=".PDF"] {
			&::before {
				content: "\f1c1";

			}
		}

		// ZIP
		&[href*=".zip"],
		&[href*=".ZIP"],
		&[href*=".rar"],
		&[href*=".RAR"],
		&[href*=".gz"],
		&[href*=".GZ"],
		&[href*=".tgz"],
		&[href*=".TGZ"]{
			&::before {
				content: "\f1c6";

			}
		}

		// IMG
		&[href*=".jpg"],
		&[href*=".JPG"],
		&[href*=".jpeg"],
		&[href*=".JPEG"],
		&[href*=".png"],
		&[href*=".PNG"],
		&[href*=".bmp"],
		&[href*=".BMP"],
		&[href*=".eps"],
		&[href*=".EPS"],
		&[href*=".gif"],
		&[href*=".GIF"],
		&[href*=".tif"],
		&[href*=".TIF"],
		&[href*=".tiff"],
		&[href*=".TIFF"],
		&[href*=".raw"],
		&[href*=".RAW"],
		&[href*=".odg"],
		&[href*=".ODG"] {
			&::before {
				content: "\f1c5";

			}
		}

		// AUDIO
		&[href*=".mp3"],
		&[href*=".MP3"],
		&[href*=".wav"],
		&[href*=".WAV"],
		&[href*=".wma"],
		&[href*=".WMA"],
		&[href*=".m4a"],
		&[href*=".M4A"],
		&[href*=".au"],
		&[href*=".AU"] {
			&::before {
				content: "\f1c7";

			}
		}

		// TEXT
		&[href*=".txt"],
		&[href*=".TXT"],
		&[href*=".doc"],
		&[href*=".DOC"],
		&[href*=".docx"],
		&[href*=".DOCX"],
		&[href*=".odt"],
		&[href*=".ODT"],
		&[href*=".pages"],
		&[href*=".PAGES"] {
			&::before {
				content: "\f1c2";

			}
		}

		// VIDEO
		&[href*=".mp4"],
		&[href*=".MP4"],
		&[href*=".avi"],
		&[href*=".AVI"],
		&[href*=".wmv"],
		&[href*=".WMV"],
		&[href*=".mpg"],
		&[href*=".MPG"],
		&[href*=".mpeg"],
		&[href*=".MPEG"],
		&[href*=".ogv"],
		&[href*=".OGV"],
		&[href*=".webm"],
		&[href*=".WEBM"],
		&[href*=".mov"],
		&[href*=".MOV"],
		&[href*=".swf"],
		&[href*=".SWF"],
		&[href*=".flv"],
		&[href*=".FLV"],
		&[href*=".wmf"],
		&[href*=".WMF"],
		&[href*=".m4v"],
		&[href*="youtube"],
		&[href*="vimeo"],
		&[href*="myvideo"] {
			&::before {
				content: "\f1c8";

			}
		}

		// Presentation
		&[href*=".ppt"],
		&[href*=".PPT"],
		&[href*=".pptx"],
		&[href*=".PPTX"],
		&[href*=".pps"],
		&[href*=".PPS"],
		&[href*=".ppsx"],
		&[href*=".PPSX"],
		&[href*=".odp"],
		&[href*=".ODP"],
		&[href*=".key"],
		&[href*=".KEY"] {
			&::before {
				content: "\f1c4";
			}
		}
	}
}

.link-list {

	li {
		margin-bottom: remcalc(10px);
	}

	a {
		font-weight: 600;
	}
}

.striped-list {

	> li:nth-of-type(odd) {
		background-color: $gray-200;
	}

	li {
		padding: remcalc(20px) remcalc(15px);

		> *:last-child {
			margin-bottom: 0;
		}

		.name {
			margin-bottom: 0;
		}
	}
}

.categories {

	.leseclubs-icon,
	.media-labs-icon {
		padding-top: remcalc(70px);
		position: relative;

		&::after {
			content: '';
			position: absolute;
			left: 50%;
			top: remcalc(20px);
			transform: translateX(-50%);
			display: block;
			height: remcalc(30px);
			width: remcalc(75px);
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;
		}
	}

	.leseclubs-icon {
		&::after {
			background-image: url("../Images/leseclubs-logo.png");
		}
	}

	.media-labs-icon {
		&::after {
			background-image: url("../Images/medialabs-logo.png");
		}
	}

	@include media-breakpoint-up(ss) {

		.leseclubs-icon,
		.media-labs-icon {
			padding-top: remcalc(20px);
			padding-right: remcalc(105px);

			&::after {
				top: 50%;
				right: remcalc(10px);
				transform: translateY(-50%);
				left: auto;

			}
		}
	}
}

.pagination {
	list-style-type: none;
	margin: 0;
	padding: 0;

	li {
		display: inline-block;

		a {
			display: block;
			text-decoration: none;
			padding: remcalc(10px) remcalc(20px);
			border: {
				top: 1px solid map-get($theme-colors, primary);
				left: 1px solid map-get($theme-colors, primary);
				bottom: 1px solid map-get($theme-colors, primary);
			}
		}

		&.active {

			a {
				background-color: map-get($theme-colors, primary);
				color: $white;
			}
		}

		&.disabled {

			a  {

				@include hover-state {
					cursor: default;
					color: map-get($theme-colors, primary);
				}

			}
		}

		&:first-child {

			a {
				border-top-left-radius: remcalc(5px);
				border-bottom-left-radius: remcalc(5px);
			}
		}

		&:last-child {

			a {
				border: {
					right: 1px solid map-get($theme-colors, primary);
				}
				border-top-right-radius: remcalc(5px);
				border-bottom-right-radius: remcalc(5px);
			}
		}
	}
}

.breadcrumb {
	margin-bottom: 0;

	a {
		text-decoration: none;

		> span {
			transition: color 200ms ease-in-out;
		}

		@include hover-state {

			> span {
				color: map-get($theme-colors, secondary);
			}
		}
	}
}