#banny-banner {
    #banny-buttons {
        .btn {
            color: #fff;
        }
    }


        a {
            color: #007988 !important;

            &:hover {
                color: #00a6bb !important;
            }
        }

}

//iframe[data-src]:not([src]):not([srcdoc]) {
//    display: none;
//    opacity: 0;
//}