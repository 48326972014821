.footer {
	background-color: #007988;
	position: relative;
	border-top: solid 1px #e5f1f3;
	padding-bottom: remcalc(200px) !important;

	* {
		color: $white;
	}

	.footer-logo {
		display: block;
		position: absolute;
		right: 0;
		bottom: remcalc(50px);
		height: remcalc(130px);
		background-color: $white;

		a {
			display: inline-block;
			&:first-child {
				img {
					width: remcalc(145px);
					height: remcalc(130px);
				}
			}
			&:last-child {
				img {
					width: remcalc(161px);
					height: remcalc(130px);

				}
			}
		}
	}

	.footer-links {
		font-weight: 600;
		margin-bottom: 0;

		> li {
			display: inline-block;
		    margin: {
		    	right: remcalc(20px);
				top: remcalc(10px);
		    	bottom: remcalc(10px);
		    }

			a, span {
				cursor: pointer;
				text-decoration: none;
				transition: color 200ms ease-out;

				@include hover-state {
					color: map-get($theme-colors, secondary);
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		padding: {
			top: remcalc(70px);
			bottom: remcalc(70px) !important;
		}

		.footer-links {
			margin-right: remcalc(330px);
		}

		.footer-logo {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}